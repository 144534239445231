<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Datos Básicos</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="name"
                :counter="nameMax"
                :maxlength="nameMax"
                :rules="nameRules"
                label="Nombre"
                required
            ></v-text-field>

            <v-textarea
                v-model="description"
                :counter="descriptionMax"
                :maxlength="descriptionMax"
                :rules="descriptionRules"
                rows="3"
                label="Descripción"
                required
            ></v-textarea>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            name: '',
            nameMin: 0,
            nameMax: 1,
            nameRules: [
                v => !!v || 'El Nombre de la Oficina es requerido',
                v => (v && v.length >= this.nameMin) || `El Nombre debe tener al menos ${this.nameMin} letras`,
                v => (v && v.length <= this.nameMax) || `El Nombre no debe superar las ${this.nameMax} letras`,
            ],
            description: '',
            descriptionMin: 0,
            descriptionMax: 1,
            descriptionRules: [
                v => !!v || 'La Descripción de la Oficina es requerido',
                v => (v && v.length >= this.descriptionMin) || `La Descripción debe tener al menos ${this.descriptionMin} letras`,
                v => (v && v.length <= this.descriptionMax) || `La Descripción no debe superar las ${this.descriptionMax} letras`,
            ],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.name = value.name;
                        this.description = value.description;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 2,
            valid: this.valid,
            data: {
                name: this.name,
                description: this.description
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        schemaData.forEach(schema => {
            if (schema.store === "office") {
                this.nameMin = schema.data.name.min;
                this.nameMax = schema.data.name.max;
                this.descriptionMin = schema.data.description.min;
                this.descriptionMax = schema.data.description.max;
            }
        });
        this.mountedFinish = true;
    },
}
</script>