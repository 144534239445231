<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Imágen de Portada</h2>

        <v-col cols="auto">
            <v-dialog
                transition="dialog-top-transition"
                max-width="700"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div
                        v-bind="attrs"
                        v-on="on"
                        ref="showModalImage"
                        style="display: none"
                    ></div>
                    <input
                        ref="inputImage"
                        @change="cropNow"
                        type="file"
                        name="banner"
                        accept=".png,.jpg,.jpeg,.gif,.webp"
                        class="fileArea__input"
                        hidden
                    />
                    <div
                        @click="clickFileInput"
                        @dragover.prevent="overFile"
                        @dragleave="leaveFile"
                        @dragend="leaveFile"
                        @drop.prevent="getFile($event)"
                        ref="fileArea"
                        class="fileArea rounded d-flex align-items-center justify-content-center"
                    >
                        <p ref="fileAreaPrompt" class="fileArea__prompt">De click aquí para subir una imagen</p>
                    </div>
                </template>
                <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar
                    color="primary"
                    dark
                    >Recortar Imagen</v-toolbar>
                    <v-card-text>
                    <div>
                        <img
                            class="cropImage"
                            :src="imgSrc"
                            ref="imageCropper"
                            alt="Office Banner"
                        >
                    </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            color="primary"
                            @click="cropImageCanvas(dialog)"
                        >Recortar</v-btn>
                        <v-btn
                            text
                            @click="cropper.destroy(); dialog.value = false"
                        >Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-card>
</template>

<style lang="css">
    .cropImage {
        max-height: 400px;
        max-width: 600px;
        object-fit: contain;
    }
    .fileArea {
        cursor: pointer;
        width: 100%;
        height: 360px;
        border: 3px rgb(92, 92, 92) dashed;
        position: relative;
    }
    .fileArea__prompt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .fileArea--over {
        border: 2px black solid;
        transform: rotate(-2deg);
    }
    @media only screen and (max-width: 600px) {
        .fileArea {
            height: 170px;
        }
    }
</style>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import Storage from "@/modules/storage";
const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: true,
            imgSrc: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
            cropper: {},
            imageCropper: {},
            valid: true,
        }
    },
    props: ['validate', 'default'],
    watch: {
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        if (value.bannerRoute) {
                            const fileAreaPrompt = this.$refs.fileAreaPrompt;
                            const fileArea = this.$refs.fileArea;
                            const fileAreaThumb = document.createElement("div");
                            fileAreaThumb.classList.add("fileArea__thumb");
                            fileAreaThumb.classList.add("rounded");
                            fileArea.appendChild(fileAreaThumb);
                            fileAreaThumb.style.backgroundImage = `url('${value.bannerRoute}')`;
                            fileAreaThumb.style.backgroundColor = "var(--main-bg)";
                            fileAreaThumb.style.backgroundSize = "cover";
                            fileAreaThumb.style.backgroundPosition = "center";
                            fileAreaThumb.style.height = "100%";
                            fileAreaThumb.style.width = "100%";
                            this.imgSrc = value.bannerRoute;
                            fileAreaPrompt.remove();
                        }
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    methods: {
        cropImageCanvas(dialog) {
            const canvas = this.cropper.getCroppedCanvas();
            const fileAreaThumb = document.getElementsByClassName(
                "fileArea__thumb"
            )[0];
            fileAreaThumb.style.backgroundImage = `url('${canvas.toDataURL()}')`;
            this.cropper.destroy();
            dialog.value = false;
            canvas.toBlob((fileData) => {
                this.$emit('valid', {
                    step: 1,
                    valid: this.valid,
                    data: {
                        file: fileData
                    }
                })
            });
            // para pasarlo a imagen es con canvas.toBlob();
        },
        clickFileInput() {
            this.$refs.inputImage.click();
        },
        overFile() {
            this.$refs.fileArea.classList.add("fileArea--over");
        },
        leaveFile() {
            this.$refs.fileArea.classList.remove("fileArea--over");
        },
        updateThumbnail(fileArea, fileAreaThumb, fileAreaPrompt, files) {
            this.$refs.showModalImage.click();
            if (fileAreaPrompt) {
                fileAreaPrompt.remove();
            }
            if (!fileAreaThumb) {
                fileAreaThumb = document.createElement("div");
                fileAreaThumb.classList.add("fileArea__thumb");
                fileAreaThumb.classList.add("rounded");
                fileArea.appendChild(fileAreaThumb);
            }
            if (files[0].type.startsWith("image/")) {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = () => {
                    fileAreaThumb.style.backgroundImage = `url('${reader.result}')`;
                    fileAreaThumb.style.backgroundColor = "var(--main-bg)";
                    fileAreaThumb.style.backgroundSize = "cover";
                    fileAreaThumb.style.backgroundPosition = "center";
                    fileAreaThumb.style.height = "100%";
                    fileAreaThumb.style.width = "100%";
                    this.imageCropper = this.$refs.imageCropper;
                    this.imageCropper.src = reader.result;
                    this.imgSrc = reader.result;
                    this.cropper = new Cropper(this.imageCropper, {
                        zoomable: false,
                        scalable: false,
                        aspectRatio: 16/9,
                        ready: function() {
                            this.cropper.zoomTo(0);  
                            this.cropper.zoomTo(1);     
                        },
                    });
                };
            } else {
                fileAreaThumb.style.backgroundImage = null;
            }
        },
        getFile(event) {
            const fileArea = this.$refs.fileArea;
            const fileAreaInput = this.$refs.inputImage;
            const fileAreaPrompt = this.$refs.fileAreaPrompt;
            const files = event.dataTransfer.files;
            const fileAreaThumb = document.getElementsByClassName(
                "fileArea__thumb"
            )[0];
            if (files.length) {
                fileAreaInput.files = files;
                this.$emit('valid', {
                    step: 1,
                    valid: this.valid,
                    data: {
                        file: files
                    }
                })
                this.updateThumbnail(fileArea, fileAreaThumb, fileAreaPrompt, files);
            }
            fileArea.classList.remove("fileArea--over");
        },
        cropNow() {
            const fileArea = this.$refs.fileArea;
            const fileAreaPrompt = this.$refs.fileAreaPrompt;
            const fileAreaThumb = document.getElementsByClassName(
                "fileArea__thumb"
            )[0];
            const fileAreaInput = this.$refs.inputImage;

            if (fileAreaInput.files.length) {
                const files = fileAreaInput.files;
                this.updateThumbnail(fileArea, fileAreaThumb, fileAreaPrompt, files);
            }
        }
    },
}
</script>